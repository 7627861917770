<template>
  <div class="w-full flex flex-row">
    <div v-if="loading" class="spinner">
      <a-spin>
        <a-icon slot="indicator" type="loading" style="font-size: 64px" spin />
      </a-spin>
    </div>
    <div
      v-if="complaint && !loading"
      class="w-full md:w-10/12 max-w-screen-xl flex flex-col lg:flex-col lg:items-start"
    >
      <GeneralManagement :complaint="complaint" :customer="customer" />
      <AnnexesManagement :complaint="complaint" :references="references" />
      <AnswerManagement :complaint="complaint" />
      <CriterionManagement
        :complaint="complaint"
        :criterion="criterion"
        :criterion_id="criterion_id"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { format } from "date-fns";
import GeneralManagement from "@/components/Dashboard/Complaints/ComplaintManagement/GeneralManagement";
import AnnexesManagement from "@/components/Dashboard/Complaints/ComplaintManagement/AnnexesManagement";
import AnswerManagement from "@/components/Dashboard/Complaints/ComplaintManagement/AnswerManagement";
import CriterionManagement from "@/components/Dashboard/Complaints/ComplaintManagement/CriterionManagement";

export default {
  components: {
    GeneralManagement,
    AnnexesManagement,
    AnswerManagement,
    CriterionManagement,
  },
  data() {
    return {
      customer: null,
      complaint: null,
      criterion: null,
      criterion_id: null,
      loading: false,
      references: "!,2,6,7", // !:not in. 2:attorney. 6:management. 7:answer
    };
  },
  async created() {
    const { complaint_code, criterion_id } = this.$route.params;
    this.criterion_id = criterion_id;
    this.loading = true;
    let { error, data } = await this.$api.getDetailComplaint(complaint_code);
    this.loading = false;

    if (error) this.showToast("error", "Error recuperando datos de la queja.");

    if (data) {
      const complaint = data;
      const numero_id_CF = complaint.numero_id_CF;
      let identificationType_id = 1; // Por defecto 1
      const objIdentificationType =
        this.$store.state.form.optionsIdentificationType?.filter(
          (identificationType) =>
            identificationType.name == complaint?.tipo_id_CF
        );
      if (objIdentificationType.length > 0) {
        identificationType_id = objIdentificationType[0].id;
      }
      this.complaint = {
        ...complaint,
        lgbtiq:
          complaint.lgbtiq == 1 ? "Sí" : complaint.lgbtiq == 2 ? "No" : "",
        specialCondition:
          complaint.condicion_especial === "No aplica"
            ? 2
            : complaint.condicion_especial == null
            ? null
            : 1,
        es_apoderado: complaint.apoderado_nombre ? 2 : 1,
        abroad: complaint.departamento_cod && complaint.municipio_cod ? 2 : 1,
        documentacion_final: complaint.documentacion_rta_final ? "Sí" : "No",
        expres: complaint.queja_expres === 1 ? "Sí" : "No",
        tutela_nombre:
          complaint.tutela === 1
            ? "La queja o reclamo tiene asociada una acción de tutela"
            : "La queja o reclamo tiene NO asociada una acción de tutela",
        foreign: complaint.departamento_cod === null ? true : false,
        fecha_cierre: complaint.fecha_cierre
          ? format(new Date(complaint.fecha_cierre), "dd/MM/yyyy hh:mm a")
          : "",
        archivos_apoderado: complaint?.mis_archivos.filter(
          (item) => item.reference === "attorney"
        ),
      };
      this.criterion = this.complaint?.evaluations.map((item) => {
        return { ...item, completed: item.revised };
      });

      this.loading = true;
      // RQ5 - si la queja está cerrada se traen desde Bigquery
      let result = null;
      if (complaint.user_history_id) {
        result = await this.$api.getQueryPersonHistory(
          `?identification_number=${numero_id_CF}&user_history=${complaint.user_history_id}`
        );
      } else {
        result = await this.$api.getQueryPerson(
          `?identification_number=${numero_id_CF}&identification_type=${identificationType_id}`
        );
      }

      this.loading = false;

      if (result.error) {
        this.showToast(
          "error",
          "Error recuperando información del usuario asociado a la queja"
        );
        return;
      }

      if (result.data) {
        if (result.data.results.length === 0) {
          this.customer = {
            identification_type_name: this.complaint.tipo_id_CF,
            identification_number: this.complaint.numero_id_CF,
            person_type: this.complaint.tipo_persona === "Natural" ? 1 : 2,
            first_name: this.complaint.nombres,
            last_name: null,
          };
        } else if (!complaint.user_history_id) {
          const user = result.data.results[0];

          this.customer = {
            identification_type_name: this.complaint.tipo_id_CF,
            identification_number: this.complaint.numero_id_CF,
            birth_date: user.birth_date,
            person_type: user.person_type,
            first_name: user.user_id.first_name,
            last_name: user.user_id.last_name,
            business_name: user.user_id?.business_name,
            phone: user.user_id.phone,
            email: user.user_id.email,
            departamento_cod: user.user_id?.departamento_cod,
            municipio_cod: user.user_id?.municipio_cod,
            address: user.user_id?.address,
          };
        } else if (complaint.user_history_id) {
          const user = result.data.results[0];
          this.customer = {
            identification_type_name: this.complaint.tipo_id_CF,
            identification_number: this.complaint.numero_id_CF,
            birth_date: user.birth_date,
            person_type: this.complaint.tipo_persona === "Natural" ? 1 : 2,
            first_name: user.first_name,
            last_name: user.last_name,
            business_name: user?.business_name,
            phone: user.phone,
            email: user.email,
            departamento_cod: user?.departamento_cod,
            municipio_cod: user?.municipio_cod,
            address: user?.address,
          };
        }
      }
    }
  },
  computed: {
    ...mapState({
      formOptions: (state) => {
        return state.form;
      },
    }),
  },
  methods: {
    showToast(result = "info", msg) {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => {},
      });
    },
  },
};
</script>

<style>
.spinner {
  width: 100vw;
  z-index: 9000;
  margin-top: 10%;
  position: relative;
}
</style>